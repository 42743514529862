@media only screen and (min-width: 1800px) {
    .navbar-area {
        .main-nav {
            width: 70%;
            left: 15%;
        }
    }
    .shape-ellips {
        margin-top: -8%;
    }
    .choose-area {
        .home-choose-img {
            &::before {
                height: 745px;
                left: -15%;
            }
        }
        .home-choose-content {
            .section-tittle {
                p {
                    padding-right: 22%;
                }
            }
        }
    }
    .left-shape {
        left: 0;
        max-width: 100%;
        margin-top: 10%;
    }
    .home-admission {
        .single-addmission {
            left: 12%;
            .admission-circle {
                left: 20%;
            }
        }
    }
    .area-404 img {
        max-width: 100%;
    }
    .footer-area {
        .footer-left {
            p {
                padding-right: 10%;
            }
        }
    }
    .home-special-course {
        .section-tittle {
            p {
                margin: auto 35%;
            }
        }
    }
}

@media only screen and (min-width: 1600px) {
    .home-special-course {
        .single-home-special-course {
            &:hover {
                .course-img {
                    .course-content {
                        h2 {
                            bottom: 170px;
                        }
                        p {
                            bottom: 60px;
                        }
                        a {
                            bottom: 0;
                        }
                    }
                }
            }
            .course-img {
                .course-content {
                    p {
                        padding: 10px 70px;
                    }
                    .box-btn {
                        left: 36%;
                    }
                }
            }
        }
    }
    .home-admission {
        .single-addmission {
            .loon {
                top: -15%;
                right: 20%;
                max-width: 4%;
            }
        }
    }
    .left-shape {
        left: 0;
        max-width: 100%;
        margin-top: 10%;
    }
}
/* Normal desktop :992px. */

@media (min-width: 992px) and (max-width: 1200px) {
    .box-btn {
        padding: 12px 25px !important;
        font-size: 15px;
    }
    .border-btn {
        padding: 12px 25px !important;
        font-size: 15px;
    }
    .shape-ellips,
    .left-shape {
        display: none;
    }
    .slider-area {
        height: 100%;
        .home-slider {
            .single-slider {
                height: 650px;
                .slider-tittle {
                    padding: 0;
                    h1 {
                        font-size: 75px;
                        line-height: normal;
                    }
                }
            }
        }
    }
    .service-area {
        .single-service {
            .service-content {
                p {
                    font-size: 16px;
                }
            }
        }
    }
    .home-ragular-course {
        padding: 0;
        padding-bottom: 30px;
        .section-tittle {
            p {
                margin: 0 20%;
            }
        }
        .single-ragular-course {
            .course-img {
                h2 {
                    font-size: 25px;
                }
            }
        }
    }
    .home-admission {
        .single-addmission {
            left: 10px;
            background-size: contain;
            .admission-circle {
                width: 390px;
                height: 390px;
            }
            .admission-content {
                left: 60px;
                h2 {
                    font-size: 25px;
                }
                .box-btn {
                    margin-top: 20px;
                }
            }
        }
    }
    .course-slider-area {
        .course-slider {
            .course-slider-content {
                max-width: 100%;
                margin: 0;
                h2 {
                    font-size: 25px;
                }
                p {
                    margin: 0;
                }
            }
        }
        .course-slider-btn {
            display: inline-flex;
        }
    }
    .home-special-course,
    .home-news,
    .home-teachers-area {
        .section-tittle {
            p {
                margin: 0 20%;
            }
        }
    }
    .home-contact-area {
        .home-contact-content {
            padding: 30px;
            h2 {
                font-size: 30px;
            }
        }
    }
    .contact-service {
        .single-service {
            .service-content {
                p {
                    font-size: 16px;
                }
            }
        }
    }
    .choose-area {
        .home-choose-img {
            padding-right: 40px;
            &::before {
                height: 127%;
            }
        }
        .home-choose,
        .home-choose-content {
            .section-tittle {
                position: relative;
                left: -12px;
            }
            padding: 0;
            .col-md-5 {
                padding-left: 0;
            }
            .col-md-7 {
                padding-left: 0;
            }
            .home-choose-content {
                p {
                    font-size: 15px;
                }
            }
            ul {
                li {
                    font-size: 14px;
                }
            }
        }
    }
    .home-admission {
        padding: 0 40px;
        .single-addmission {
            .admission-circle {
                left: -60px;
            }
            .admission-content {
                left: 40px;
                h2 {
                    font-size: 23px;
                }
            }
            .loon {
                display: none;
            }
        }
    }
    .home-special-course {
        .single-home-special-course {
            &:hover {
                .course-img {
                    .course-content {
                        h2 {
                            bottom: 170px;
                        }
                        p {
                            bottom: 70px;
                        }
                    }
                }
            }
            .course-img {
                .course-content {
                    p {
                        padding: 5px 10px;
                    }
                }
            }
        }
    }
    .news {
        .pagination {
            margin-top: 20px;
            margin-bottom: 0;
        }
        .single-news {
            .content {
                padding: 20px;
                a {
                    h2 {
                        font-size: 17px;
                    }
                }
            }
        }
    }
    .single-news-area {
        .share {
            right: -60%;
            bottom: 35px;
        }
    }
    .about-area {
        .about-btn {
            display: inline-flex;
            .video-pop {
                margin-top: 12px;
            }
        }
    }
    .home-contact-area {
        .list-unstyled {
            font-size: 14px;
        }
    }
    .events,
    .home-news {
        .single-events,
        .single-home-news {
            .content,
            .single-home-content {
                padding: 30px 24px;
                h2 {
                    font-size: 17px;
                }
            }
        }
    }
    .price-area {
        .single-price {
            .price-head {
                h2 {
                    font-size: 25px;
                }
                p {
                    font-size: 30px;
                }
            }
        }
    }
    .single-event {
        .right-content {
            .single-content {
                .content {
                    padding: 30px 20px;
                    a {
                        h2 {
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
    .single-news-area {
        .news-content-right {
            .consultation-area {
                h2 {
                    font-size: 19px;
                }
            }
        }
        .more-news {
            .single-news {
                .content {
                    padding: 30px 25px;
                    a {
                        h2 {
                            font-size: 19px;
                        }
                    }
                }
            }
        }
    }
}
/* Tablet desktop :768px. */

@media (min-width: 768px) and (max-width: 991px) {
    .mean-container {
        .mean-nav {
            max-height: 700px;
        }
    }
    .page-title-content {
        margin-top: 40px;
        h2 {
            font-size: 25px;
            margin-bottom: 0;
        }
        p {
            font-size: 15px;
        }
        ul {
            li {
                a {
                    font-size: 15px;
                }
            }
        }
    }
    .box-btn {
        padding: 12px 25px !important;
    }
    .border-btn {
        padding: 12px 25px !important;
    }
    .ps-0, .px-0 {
        padding-left: 20px!important;
    }
    .shape-ellips,
    .left-shape {
        display: none;
    }
    .navbar-area {
        a.logo {
            width: 14%;
        }
        top: 0;
        a.said-bar-list {
            display: none;
        }
    }
    .slider-area {
        height: 100%;
        .home-slider {
            .single-slider {
                height: 650px;
                .slider-tittle {
                    padding: 0;
                    h1 {
                        font-size: 55px;
                        line-height: normal;
                        padding-top: 0%;
                    }
                    p {
                        padding: 0;
                    }
                }
            }
        }
    }
    .service-area {
        bottom: 0;
        padding: 50px 0;
        .single-service {
            .service-content {
                p {
                    font-size: 18px;
                }
            }
        }
        .sst-10 {
            position: relative;
            margin-top: 30px;
        }
    }
    .home-ragular-course {
        padding-bottom: 50px;
        .section-tittle {
            margin-bottom: 10px;
            p {
                margin: 0 10%;
            }
        }
        .single-ragular-course {
            margin-top: 20px;
            .course-content {
                padding: 25px 10px;
            }
            .course-img {
                h2 {
                    font-size: 28px;
                }
            }
        }
        .sst-10 {
            position: relative;
            left: 25%;
            top: 10px;
        }
    }
    .choose-area {
        padding: 70px 0;
        .home-choose-img {
            padding-right: 0px;
            &::before {
                display: none;
            }
        }
    }
    .home-choose-content {
        margin-top: 3%;
        .section-tittle {
            p {
                padding-right: 0;
            }
        }
        .choose-list-home {
            padding-left: 0;
        }
        .box-btn {
            display: block;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-66%, -5%);
            padding: 13px 25px !important;
        }
    }
    .home-admission {
        padding-bottom: 70px;
        padding-top: 0;
        .single-addmission {
            background: #1d42d9;
            border-radius: 230px 0 0 230px;
            left: 0;
            .admission-circle {
                width: 300px;
                height: 300px;
                border: 20px solid #1d42d9;
                top: 5px;
                left: -13px;
                h2 {
                    font-size: 45px;
                }
            }
            .admission-content {
                left: 15px;
                top: 18px;
                h2 {
                    padding-top: 0;
                    font-size: 18px;
                    line-height: normal;
                }
                p {
                    font-size: 14px;
                }
                .admission-list {
                    padding-right: 45px;
                    p {
                        font-size: 25px !important;
                        line-height: 65px;
                        margin-bottom: 0px;
                    }
                    span {
                        margin-bottom: 5px;
                        font-size: 17px;
                        line-height: 45px;
                    }
                    li {
                        p {
                            width: 70px;
                            height: 70px;
                            line-height: 65px;
                        }
                    }
                }
                .box-btn {
                    margin-top: 20px;
                }
            }
        }
    }
    .home-special-course {
        padding: 70px 0;
        .section-tittle {
            margin-bottom: 10px;
            p {
                margin: 0 10%;
            }
        }
        .owl-nav {
            button.owl-prev {
                left: 0;
            }
            button.owl-next {
                right: 0;
            }
        }
    }
    .course-slider-area {
        padding-bottom: 70px;
        .course-slider {
            .course-slider-content {
                max-width: 100%;
                margin: 0;
                h2 {
                    font-size: 25px;
                }
                p {
                    margin: 0;
                }
            }
        }
        .course-slider-btn {
            display: inline-flex;
            .box-btn,
            .border-btn {
                padding: 10px 10px !important;
                font-size: 14px;
            }
        }
    }
    .home-special-course {
        .single-home-special-course {
            &:hover {
                .course-img {
                    .course-content {
                        h2 {
                            bottom: 170px;
                        }
                        p {
                            bottom: 60px;
                        }
                        a {
                            bottom: 0;
                        }
                    }
                }
            }
            .course-img {
                .course-content {
                    p {
                        padding: 10px 30px;
                    }
                }
            }
        }
    }
    .home-news {
        padding: 70px 0;
        .section-tittle {
            margin-bottom: 10px;
            p {
                margin: 0 10%;
            }
        }
        .sst-10 {
            position: relative;
            left: 25%;
            top: 10px;
        }
    }
    .home-teachers-area {
        padding-top: 0;
        padding-bottom: 70px;
        .section-tittle {
            margin-bottom: 10px;
            p {
                margin: 0 10%;
            }
        }
    }
    .home-contact-area {
        padding-top: 0;
        padding-bottom: 70px;
        .home-contact-content {
            left: 0;
        }
    }
    .footer-area {
        padding: 70px 0;
        .fml-15 {
            margin-left: 0%;
            margin-top: 20px;
        }
        .fml-25,
        .fml-20 {
            margin-left: 20%;
        }
    }
    .banner-area {
        height: 350px;
        &::before {
            background-size: cover;
            background-position: center center;
        }
    }
    .about-area {
        padding: 50px 0;
        .single-about {
            .about-contnet {
                p {
                    padding-right: 0;
                }
            }
        }
        .about-btn {
            display: inline-flex;
            .video-pop {
                .video {
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    margin: 0 0px 0 0px;
                }
                margin-top: 25px;
            }
        }
    }
    .single-class-area {
        padding: 50px 0;
        .single-class {
            .class-contnet {
                p {
                    padding-right: 0;
                }
            }
        }
    }
    .events {
        padding-top: 50px;
        padding-bottom: 20px;
    }
    .single-event {
        padding-top: 50px;
        padding-bottom: 50px;
        .event-slider {
            .content {
                h2 {
                    font-size: 22px;
                    padding-bottom: 15px;
                }
            }
        }
        .share {
            right: 25px;
            bottom: -1%;
        }
        .right-content {
            .single-content {
                .content {
                    padding: 20px 15px;
                    a {
                        h2 {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    .shape-ellips-contact {
        display: none;
    }
    .contact-service {
        padding-top: 50px;
        bottom: 0;
        .single-service {
            .service-content {
                p {
                    font-size: 18px;
                }
            }
        }
        .sst-10 {
            position: relative;
            left: 55%;
            top: 30px;
        }
    }
    .price-area {
        padding: 50px 0;
        .sst-10 {
            position: relative;
            left: 50%;
        }
    }
    .faq-area {
        padding: 50px 0;
    }
    .news {
        padding: 50px 0;
        .pagination {
            margin-top: 20px;
            margin-bottom: 0;
        }
        .single-news {
            .content {
                padding: 20px;
                a {
                    h2 {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    .price-area {
        padding-top: 20px;
        padding-bottom: 50px;
        .single-price {
            margin-top: 30px;
        }
    }
    .privecy-area,
    .terms-area,
    .single-teacher-area {
        padding: 50px 0;
    }
    .single-news-area {
        .share {
            right: -40%;
            bottom: 35px;
        }
        .news-content-right {
            .consultation-area {
                h2 {
                    font-size: 19px;
                }
            }
        }
    }
    .home-news {
        .single-home-news {
            .single-home-content {
                h2 {
                    font-size: 18px;
                }
            }
        }
    }
    .contact-service {
        .sst-10 {
            left: 0;
        }
    }
}
/* small mobile :320px. */

@media (max-width: 767px) {
    .mean-container {
        .mean-nav {
            max-height: 700px;
            overflow: auto;
        }
    }
    .shape-ellips,
    .left-shape {
        display: none;
    }
    .box-btn {
        padding: 10px 20px;
        font-size: 15px;
        margin-right: 0;
    }
    .border-btn {
        padding: 9px 20px;
        font-size: 15px;
        margin-right: 0;
    }
    .section-tittle {
        h2 {
            font-size: 25px;
        }
    }
    p {
        font-size: 15px !important;
    }
    .page-title-content {
        margin-top: 50px;
        h2 {
            font-size: 25px;
            margin-bottom: 0;
        }
        p {
            font-size: 15px;
        }
        ul {
            li {
                a {
                    font-size: 15px;
                }
            }
        }
    }
    .navbar-area {
        a.logo {
            width: 25%;
        }
        top: 0;
        a.said-bar-list {
            display: none;
        }
    }
    .slider-area {
        height: 100%;
        .home-slider {
            .single-slider {
                height: 100%;
                .slider-tittle {
                    padding: 0;
                    padding-top: 90px;
                    h1 {
                        font-size: 25px;
                        line-height: normal;
                    }
                    p {
                        padding: 0;
                        margin-bottom: 0;
                        margin-top: 12px;
                    }
                }
            }
        }
        .owl-nav {
            display: none !important;
        }
        .slider-btn {
            padding-bottom: 20px;
            .box-btn {
                padding: 10px 20px;
                font-size: 15px;
                margin-right: 0;
            }
            .border-btn {
                padding: 9px 20px;
                font-size: 15px;
                margin-right: 0;
            }
        }
    }
    .about-area {
        .about-content-right {
            .consultation-area {
                .box-btn {
                    padding: 10px 30px;
                    font-size: 15px;
                }
            }
        }
    }
    .service-area {
        bottom: 0;
        .single-service {
            margin-top: 20px;
            .service-icon {
                margin-bottom: 10px;
            }
            h2 {
                font-size: 20px;
            }
        }
    }
    .home-ragular-course {
        padding: 50px 0;
        .section-tittle {
            margin-bottom: 10px;
            p {
                margin: 0;
            }
        }
        .single-ragular-course {
            .course-img {
                h2 {
                    font-size: 25px;
                }
            }
            margin-top: 20px;
            .course-content {
                padding: 25px 10px;
            }
        }
    }
    .choose-area {
        padding: 50px 0;
        .home-choose-img {
            padding-right: 0px;
            &::before {
                height: 140%;
            }
        }
        .home-choose-content {
            margin-top: 20%;
            .section-tittle {
                p {
                    padding-right: 0;
                }
            }
            .choose-list-home {
                padding-left: 0;
            }
            .box-btn {
                left: 10px;
                padding: 10px 20px;
            }
        }
    }
    .home-admission {
        padding-bottom: 50px;
        padding-top: 0;
        .admission-shape1,
        .admission-shape2,
        .admission-shape3,
        .admission-shape4 {
            display: none;
        }
        .single-addmission {
            background: #1d42d9;
            border-radius: 230px 230px 0 0;
            left: 0;
            .admission-circle {
                width: 300px;
                height: 300px;
                border: 30px solid #1d42d9;
                position: relative;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);
                h2 {
                    font-size: 35px;
                }
            }
            .admission-content {
                left: 0;
                padding: 0 20px;
                text-align: center;
                h2 {
                    padding-top: 0;
                    font-size: 20px;
                    line-height: normal;
                }
                p {}
                .admission-list {
                    padding-right: 25px;
                    padding-left: 25px;
                    p {
                        font-size: 30px !important;
                        line-height: 65px;
                        margin-bottom: 0px;
                    }
                    span {
                        margin-bottom: 5px;
                        font-size: 17px;
                        line-height: 45px;
                    }
                    li {
                        p {
                            width: 70px;
                            height: 70px;
                            line-height: 65px;
                        }
                    }
                }
                .box-btn {
                    margin-top: 20px;
                }
            }
        }
    }
    .home-special-course {
        padding: 50px 0;
        .section-tittle {
            margin-bottom: 10px;
            p {
                margin: 0;
            }
        }
        .single-home-special-course {
            .course-img {
                .course-content {
                    h2 {
                        bottom: 10px;
                    }
                    p {
                        padding: 0;
                    }
                }
            }
            &:hover {
                .course-img {
                    .course-content {
                        height: 100%;
                        h2 {
                            bottom: 170px;
                        }
                        P {
                            bottom: 60px;
                        }
                        a {
                            bottom: 0;
                        }
                    }
                }
            }
            .course-img {
                .course-content {
                    p {
                        padding: 10px 30px;
                        font-size: 14px !important;
                    }
                }
            }
        }
        .owl-nav {
            button.owl-prev {
                left: 0;
            }
            button.owl-next {
                right: 0;
            }
        }
    }
    .course-slider-area {
        padding-bottom: 50px;
        .course-slider {
            .course-slider-content {
                max-width: 100%;
                margin: 0;
                h2 {
                    font-size: 25px;
                    margin-top: 15px;
                }
                p {
                    margin: 0;
                }
            }
        }
        .course-slider-btn {
            .box-btn {
                padding: 10px 20px;
                margin-right: 0;
            }
            .border-btn {
                padding: 9px 20px;
                margin-right: 0;
                margin-top: 10px;
            }
        }
        .owl-nav {
            display: none !important;
        }
        .course-slider-btn {
            margin-bottom: 10px;
        }
    }
    .home-news {
        padding: 50px 0;
        .section-tittle {
            margin-bottom: 10px;
            p {
                margin: 0;
            }
        }
        .single-home-news {
            .single-home-content {
                padding: 20px;
                h2 {
                    font-size: 17px;
                }
            }
        }
    }
    .home-teachers-area {
        padding-top: 0;
        padding-bottom: 50px;
        .section-tittle {
            margin-bottom: 10px;
            h2 {}
            p {
                margin: 0;
            }
        }
        .single-home-teacher {
            .teachers-content {
                p {
                    font-size: 13px !important;
                }
            }
        }
    }
    .home-contact-area {
        padding-top: 0;
        padding-bottom: 50px;
        .home-contact-content {
            padding: 30px 15px;
            border-radius: 40px;
            left: 0;
            h2 {
                font-size: 25px;
                margin-bottom: 20px;
            }
        }
        .box-btn {
            padding: 10px 30px;
            font-size: 15px;
        }
        .text-danger {
            font-size: 15px;
        }
    }
    .footer-area {
        .fml-25,
        .fml-15 {
            margin-left: 0;
        }
        padding: 50px 0;
        .footer-content {
            margin-top: 20px;
            h2 {
                margin-bottom: 0;
            }
        }
        .logo {
            width: 40% !important;
        }
        .footer-left {
            p {
                margin-top: 15px;
            }
        }
    }
    .copy-area {
        text-align: center;
    }
    .banner-area {
        height: 100%;
        padding: 80px 0;
        &::before {
            background-size: cover;
            background-position: center center;
        }
    }
    .about-area {
        .visit {
            font-size: 20px !important;
        }
        padding: 50px 0;
        .single-about {
            .about-contnet {
                p {
                    padding-right: 0;
                }
            }
        }
        .about-btn {
            display: unset;
            .video-pop {
                margin: 30px 0;
            }
        }
    }
    .single-class-area {
        .visit,
        .sp-class {
            font-size: 24px !important;
        }
        padding: 50px 0;
        .single-class {
            .class-contnet {
                p {
                    padding-right: 0;
                }
            }
        }
        .class-content-right {
            margin-top: 30px;
            .consultation-area {
                h2 {
                    font-size: 21px;
                }
                .box-btn {
                    padding: 10px 30px;
                }
            }
        }
    }
    .events {
        padding: 50px 0;
        .pagination {
            margin-top: 20px;
            margin-bottom: 0;
        }
    }
    .single-event {
        padding-top: 50px;
        padding-bottom: 50px;
        .event-slider {
            .content {
                h2 {
                    padding-bottom: 15px;
                }
                p {
                    padding-right: 0;
                    margin-bottom: 10px;
                }
            }
        }
        .share {
            margin-top: 25px;
            margin-bottom: 0;
            position: unset;
        }
        .right-content {
            margin-top: 30px;
            .visit {
                font-size: 24px !important;
            }
            .single-content {
                .content {
                    padding: 30px 20px;
                    a {
                        h2 {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    .shape-ellips-contact {
        display: none;
    }
    .contact-service {
        padding: 50px 0;
        .single-service {
            margin-top: 20px;
        }
    }
    .price-area {
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .admission-area {
        padding: 50px 0;
        .admission-form {
            padding: 20px;
            h2 {
                font-size: 25px;
            }
        }
    }
    .faq-area {
        padding: 50px 0;
    }
    .news {
        padding: 50px 0;
        .pagination {
            margin-top: 20px;
            margin-bottom: 0;
        }
        .single-news {
            .content {
                padding: 20px;
                a {
                    h2 {
                        font-size: 17px;
                    }
                }
            }
        }
    }
    .price-area {
        padding-top: 20px;
        padding-bottom: 50px;
        .single-price {
            margin-top: 30px;
        }
    }
    .privecy-area,
    .terms-area {
        padding: 50px 0;
    }
    .signup-area {
        padding: 50px 0;
        .signup-form {
            h2 {
                font-size: 22px;
            }
            .box-btn {
                padding: 10px 25px;
            }
        }
    }
    .single-news-area {
        padding: 50px 0;
        .news-slider {
            .content {
                h2 {
                    font-size: 20px;
                }
                p {
                    padding-right: 0;
                    margin-bottom: 10px;
                }
            }
        }
        .m-news {
            font-size: 24px !important;
        }
        .share {
            position: relative;
            right: 0;
            bottom: 0;
            margin: 20px 0;
        }
        .more-news {
            margin-top: -10px;
            .single-news {
                .content {
                    padding: 30px 25px;
                    a {
                        h2 {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        .news-content-right {
            .consultation-area {
                h2 {
                    font-size: 21px;
                }
                .box-btn {
                    padding: 10px 20px;
                }
            }
            .visit,
            .sp-class {
                font-size: 20px !important;
            }
        }
    }
    .single-teacher-area {
        padding: 50px 0;
        .teacher-slider {
            .single-slider {
                .content {
                    p {
                        padding-right: 0;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .about-search {
            margin-top: 20px;
        }
        .visit {
            font-size: 24px !important;
        }
        .right-content {
            i.flaticon-search {
                top: 40%;
            }
        }
    }
    .teachers-area {
        padding: 50px 0;
        .pagination {
            margin: 0;
        }
    }
    .testimonials-area {
        padding: 50px 0;
        .section-tittle {
            margin-bottom: 20px;
            p {
                margin: 0;
            }
        }
        .single-testimonials {
            padding: 20px 10px;
        }
        .pagination {
            margin-top: 10px;
            margin-bottom: -10px;
        }
    }
    .about-area {
        .about-content-right {
            .consultation-area {
                h2 {
                    font-size: 21px;
                }
            }
        }
        .single-about {
            .about-contnet {
                h2 {
                    font-size: 20px;
                }
            }
        }
    }
    .price-area {
        .single-price {
            .price-head {
                h2 {
                    font-size: 25px;
                }
                p {
                    font-size: 20px !important;
                }
            }
        }
    }
    .privecy-area {
        h2 {
            font-size: 25px;
        }
    }
    .teachers-area {
        padding: 50px 0;
        .single-teacher {
            .teachers-content {
                max-width: 80%;
                margin: auto;
                left: 0;
                p {
                    font-size: 13px !important;
                }
            }
            .teacher-social {
                left: 32px;
                width: 81%;
            }
        }
    }
    .terms-area {
        h2 {
            font-size: 25px;
        }
    }
    .class-area {
        padding: 50px 0;
        .pagination {
            margin-bottom: 0;
        }
        .single-ragular-course {
            .course-img {
                h2 {
                    font-size: 27px;
                }
            }
        }
    }
    .special-single-class {
        padding: 50px 0;
        text-align: center;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .slider-area {
        .home-slider {
            .single-slider {
                .slider-tittle {
                    p {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
    .choose-area {
        .home-choose-img {
            &::before {
                height: 127%;
            }
        }
    }
    .home-special-course {
        .single-home-special-course {
            &:hover {
                .course-img {
                    .course-content {
                        h2 {
                            bottom: 170px
                        }
                        p {
                            bottom: 60px;
                        }
                        a {
                            bottom: 0;
                        }
                    }
                }
            }
            .course-img {
                .course-content {
                    p {
                        padding: 10 30px;
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
    .home-news {
        .single-home-news {
            text-align: center;
            .single-home-content {
                width: 72.5%;
                margin: auto;
                text-align: left;
                .calender {
                    font-size: 13px !important;
                }
            }
        }
    }
    .events,
    .news {
        .single-events,
        .single-news {
            text-align: center;
            .content {
                width: 72.5%;
                margin: auto;
                text-align: left;
                .calender {
                    font-size: 13px !important;
                }
            }
        }
    }
    .home-ragular-course {
        .single-ragular-course {
            max-width: 72%;
            margin: 10px auto;
        }
    }
    .about-area {
        .about-btn {
            display: unset;
            .box-btn {
                padding: 13px 25px;
                top: 25px;
            }
            .video-pop {
                margin: 30px 20px;
            }
        }
    }
    .gallery-area {
        .gall-list {
            .single-gall {
                .gall-img {
                    max-width: 87%;
                }
            }
        }
    }
    .price-area {
        .single-price {
            max-width: 75%;
            margin: auto;
            margin-top: 30px;
        }
    }
    .single-event {
        .right-content {
            .single-content {
                width: 73%;
            }
        }
    }
    .class-area {
        padding: 50px 0;
        .single-ragular-course {
            max-width: 72%;
            margin: 10px auto;
        }
    }
    .special-single-class {
        padding: 75px 0;
        .single-sp-class {
            .course-img {
                width: 100%;
                .course-content {
                    h2 {
                        bottom: 10px;
                        font-size: 25px;
                    }
                    p {
                        padding: 10px;
                        font-size: 14px !important;
                        margin-bottom: 20px;
                    }
                    .box-btn {
                        margin: auto;
                    }
                }
            }
            &:hover {
                .course-img {
                    .course-content {
                        height: 100%;
                        h2 {
                            bottom: 150px;
                        }
                        p {
                            bottom: 40px;
                        }
                        .box-btn {
                            bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}