/*================================================
Default CSS
=================================================*/
.switch-box {
  position: fixed;
  z-index: 1;
  left: 3%;
  bottom: 63px;
}

.switch-box .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-box .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-box .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #4d4949;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-box .slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 2.5px;
  bottom: 4px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0 0px 15px #2020203d;
          box-shadow: 0 0px 15px #2020203d;
  background: url(../../assets/images/night.png);
  background-repeat: no-repeat;
  background-position: center;
}

.switch-box input:checked + .slider {
  background-color: #cbc1c1;
}

.switch-box input:focus + .slider {
  background-color: #4d4949;
}

.switch-box input:checked + .slider:before {
  -webkit-transform: translateX(24px);
          transform: translateX(24px);
  background: url(../../assets/images/sunny.png);
  background-repeat: no-repeat;
  background-position: center;
}

.switch-box .slider.round {
  border-radius: 50px;
}

.switch-box .slider.round:before {
  border-radius: 50%;
}

.theme-dark body {
  background-color: #000000;
  color: #f1f1f1;
}

.theme-dark p {
  color: #f1f1f1 !important;
}

.theme-dark p a {
  color: #f1f1f1 !important;
}

.theme-dark p a:hover {
  color: #16056b !important;
}

.theme-dark a {
  color: #ffffff;
}

.theme-dark .h1, .theme-dark .h2, .theme-dark .h3, .theme-dark .h4, .theme-dark .h5, .theme-dark .h6, .theme-dark h1, .theme-dark h2, .theme-dark h3, .theme-dark h4, .theme-dark h5, .theme-dark h6 {
  color: #ffffff !important;
}

.theme-dark .h1 a, .theme-dark .h2 a, .theme-dark .h3 a, .theme-dark .h4 a, .theme-dark .h5 a, .theme-dark .h6 a, .theme-dark h1 a, .theme-dark h2 a, .theme-dark h3 a, .theme-dark h4 a, .theme-dark h5 a, .theme-dark h6 a {
  color: #ffffff !important;
}

.theme-dark .h1 a:hover, .theme-dark .h2 a:hover, .theme-dark .h3 a:hover, .theme-dark .h4 a:hover, .theme-dark .h5 a:hover, .theme-dark .h6 a:hover, .theme-dark h1 a:hover, .theme-dark h2 a:hover, .theme-dark h3 a:hover, .theme-dark h4 a:hover, .theme-dark h5 a:hover, .theme-dark h6 a:hover {
  color: #16056b !important;
}

.theme-dark .box-btn {
  background: #16056b;
  color: #ffffff;
}

.theme-dark .box-btn:hover {
  background: #6346f0;
  color: #ffffff;
}

.theme-dark .navbar-area .navbar-brand .white-logo {
  display: block;
}

.theme-dark .navbar-area .navbar-brand .main-logo {
  display: none;
}

.theme-dark .navbar-area.is-sticky {
  background-color: #000000;
}

.theme-dark .main-nav {
  background-color: #000000;
}

.theme-dark .main-nav .navbar .navbar-nav .nav-item a {
  color: #ffffff;
}

.theme-dark .main-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  background: #0e0e0e;
}

.theme-dark .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #ffffff;
  border-bottom: 1px dashed #000000;
}

.theme-dark .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #ffffff;
  border-bottom: 1px dashed #000000;
}

.theme-dark .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ffffff;
}

.theme-dark .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ffffff;
}

.theme-dark .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ffffff;
}

.theme-dark .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ffffff;
}

.theme-dark .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ffffff;
}

.theme-dark .main-nav .navbar .others-options ul li .sign-in {
  color: #ffffff;
}

.theme-dark .main-nav .navbar .others-options ul li .cart-icon i {
  color: #ffffff;
}

.theme-dark .main-nav .navbar .others-options ul li .cart-icon i:hover {
  color: #16056b;
}

.theme-dark .main-nav .navbar .others-options ul li .search-btn {
  color: #ffffff;
}

.theme-dark .main-nav .navbar .others-options ul li .search-btn:hover {
  color: #16056b;
}

.theme-dark .slider-area .home-slider .single-slider::before {
  background: unset;
  background-color: #000000;
  opacity: .7;
}

.theme-dark .slider-area .home-slider .box-btn {
  background-color: #16056b;
}

.theme-dark .banner-area::before {
  background: unset;
  background-color: #000000;
  opacity: .8;
}

.theme-dark .page-title-content ul li a::before {
  background: #ffffff;
}

.theme-dark .page-title-content ul li p::before {
  background: #ffffff;
}

.theme-dark .service-area .single-service {
  background: unset;
  background-color: #0e0e0e;
}

.theme-dark .service-area .single-service .service-content p {
  color: #ffffff;
}

.theme-dark .home-ragular-course .single-ragular-course {
  background-color: #0e0e0e;
}

.theme-dark .home-ragular-course .single-ragular-course .course-img::before {
  background: #000000;
}

.theme-dark .home-ragular-course .single-ragular-course .course-content a {
  border: 1px solid #ffffff;
  color: #ffffff;
}

.theme-dark .home-special-course {
  background: #0e0e0e;
}

.theme-dark .course-slider-area {
  background-color: #0e0e0e;
}

.theme-dark .course-slider-area .course-slider {
  background: unset;
  background-color: #000000;
}

.theme-dark .class-area .single-ragular-course {
  background: #0e0e0e;
}

.theme-dark .class-area .single-ragular-course .course-img::before {
  background: #000000;
}

.theme-dark .pagination .page-item .page-link {
  background: #0e0e0e;
  color: #fff;
}

.theme-dark .pagination .page-item .page-link:hover {
  background-color: #16056b;
  color: #ffffff;
}

.theme-dark .single-class-area .class-content-right .class-list li a {
  color: #ffffff;
}

.theme-dark .about-area .about-content-right .about-search {
  background-color: #0e0e0e;
  color: #ffffff;
}

.theme-dark .about-area .about-content-right .about-search::-webkit-input-placeholder {
  color: #ffffff;
}

.theme-dark .about-area .about-content-right .about-search:-ms-input-placeholder {
  color: #ffffff;
}

.theme-dark .about-area .about-content-right .about-search::-ms-input-placeholder {
  color: #ffffff;
}

.theme-dark .about-area .about-content-right .about-search::placeholder {
  color: #ffffff;
}

.theme-dark .about-area .about-content-right button {
  color: #ffffff;
}

.theme-dark .about-area .about-content-right .about-list li a {
  color: #ffffff;
}

.theme-dark .consultation-area {
  background-color: #0e0e0e;
}

.theme-dark .consultation-area .form-group .form-control {
  background-color: #000000;
  color: #ffffff;
}

.theme-dark .consultation-area .form-group .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.theme-dark .consultation-area .form-group .form-control:-ms-input-placeholder {
  color: #ffffff;
}

.theme-dark .consultation-area .form-group .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.theme-dark .consultation-area .form-group .form-control::placeholder {
  color: #ffffff;
}

.theme-dark .about-search {
  background-color: #0e0e0e;
  color: #ffffff;
}

.theme-dark .about-search::-webkit-input-placeholder {
  color: #ffffff;
}

.theme-dark .about-search:-ms-input-placeholder {
  color: #ffffff;
}

.theme-dark .about-search::-ms-input-placeholder {
  color: #ffffff;
}

.theme-dark .about-search::placeholder {
  color: #ffffff;
}

.theme-dark .events .single-events .content {
  background-color: #0e0e0e;
}

.theme-dark .choose-area .home-choose-content ul li {
  color: #ffffff;
}

.theme-dark .home-admission .single-addmission {
  background-image: unset;
}

.theme-dark .home-admission .single-addmission .admission-circle {
  background: #0e0e0e;
  border-color: #292727;
}

.theme-dark .admission-area .admission-form {
  background-color: #0e0e0e;
}

.theme-dark .admission-area .admission-form .form-group label {
  color: #ffffff;
}

.theme-dark .admission-area .admission-form .form-group .form-control {
  background-color: #000000;
  color: #ffffff;
}

.theme-dark .admission-area .admission-form .form-group .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.theme-dark .admission-area .admission-form .form-group .form-control:-ms-input-placeholder {
  color: #ffffff;
}

.theme-dark .admission-area .admission-form .form-group .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.theme-dark .admission-area .admission-form .form-group .form-control::placeholder {
  color: #ffffff;
}

.theme-dark .price-area .single-price {
  background-color: #0e0e0e;
}

.theme-dark .price-area .single-price .price-head {
  background-color: #2a2929;
}

.theme-dark .gallery-area .gall-list .single-gall .gall-content {
  background-color: #0e0e0e;
}

.theme-dark .testimonials-area .single-testimonials {
  background-color: #0e0e0e;
}

.theme-dark .signup-area .signup-form {
  background-color: #0e0e0e;
}

.theme-dark .signup-area .signup-form .MuiStepLabel-label{
  color: white;
}

.theme-dark .signup-area .signup-form .MuiSelect-select{
  background-color: white; 
}


.theme-dark .signup-area .signup-form input, .select{
  background-color: white;
}
.theme-dark .signup-area .signup-form a{
  color: #0d6efd;
}

.theme-dark .signup-area .signup-form .form-group .form-control {
  background-color: #000000;
  color: #ffffff;
}

.theme-dark .signup-area .signup-form .form-group .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.theme-dark .signup-area .signup-form .form-group .form-control:-ms-input-placeholder {
  color: #ffffff;
}

.theme-dark .signup-area .signup-form .form-group .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.theme-dark .signup-area .signup-form .form-group .form-control::placeholder {
  color: #ffffff;
}

.theme-dark .faq-area .faq-content .faq-accordion .accordion .accordion-item .accordion-title {
  background-color: #0e0e0e;
  color: #ffffff;
}

.theme-dark .faq-area .faq-content .faq-accordion .accordion .accordion-item .accordion-title i {
  color: #ffffff;
}

.theme-dark .faq-area .faq-content .faq-accordion .accordion .accordion-item .accordion-content {
  background-color: #0e0e0e;
}

.theme-dark .home-news .single-home-news .single-home-content {
  background-color: #0e0e0e;
}

.theme-dark .news .single-news .content {
  background-color: #0e0e0e;
}

.theme-dark .news .single-news .content a {
  color: #ffffff;
}

.theme-dark .single-news-area .news-content-right .class-list li a {
  color: #ffffff;
}

.theme-dark .home-teachers-area .single-home-teacher .teachers-content {
  background: #0e0e0e;
}

.theme-dark .home-teachers-area .single-home-teacher .teacher-social::before {
  display: none;
}

.theme-dark .teachers-area .single-teacher .teachers-content {
  background: #0e0e0e;
}

.theme-dark .teachers-area .single-teacher .teacher-social::before {
  display: none;
}

.theme-dark .home-contact-area .home-contact-content {
  background: unset;
  background-color: #0e0e0e;
}

.theme-dark .home-contact-area .home-contact-content .form-group .form-control {
  background-color: #000000;
  color: #ffffff;
}

.theme-dark .home-contact-area .home-contact-content .form-group .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.theme-dark .home-contact-area .home-contact-content .form-group .form-control:-ms-input-placeholder {
  color: #ffffff;
}

.theme-dark .home-contact-area .home-contact-content .form-group .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.theme-dark .home-contact-area .home-contact-content .form-group .form-control::placeholder {
  color: #ffffff;
}

.theme-dark .contact-service .single-service {
  background: unset;
  background-color: #0e0e0e;
}

.theme-dark .footer-area {
  background: unset;
  background-color: #222222;
}

@media only screen and (max-width: 991px) {
  .theme-dark .mean-container .mean-bar {
    background-color: #000000;
    padding: 0;
  }
  .theme-dark .mean-container a.meanmenu-reveal span {
    background-color: #ffffff;
  }
  .theme-dark .mean-container .mean-nav ul li {
    background: #000000;
  }
  .theme-dark .mean-container .mean-nav ul li a {
    color: #ffffff;
  }
  .theme-dark .mobile-nav .logo .main-logo {
    display: none;
  }
  .theme-dark .mobile-nav .logo .white-logo {
    display: block;
  }
}
