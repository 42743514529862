/*================================================
Default CSS
=================================================*/
$primary-color:#1d42d9;
$primary-color: #16056b;
$body-color: #ffffff;
$heading-color: #ffffff;
// Switch css
.switch-box {
    position: fixed;
    z-index: 1;
    left: 3%;
    bottom: 63px;

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #4d4949;
        transition: 0.4s;

        &:before {
            position: absolute;
            content: "";
            height: 30px;
            width: 30px;
            left: 2.5px;
            bottom: 4px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            transition: 0.4s;
            box-shadow: 0 0px 15px #2020203d;
            background: url(../../assets/images/night.png);
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    input:checked + .slider {
        background-color: #cbc1c1;
    }
    input:focus + .slider {
        background-color: #4d4949;
    }
    input:checked + .slider:before {
        transform: translateX(24px);
        background: url(../../assets/images/sunny.png);
        background-repeat: no-repeat;
        background-position: center;
    }
    .slider {
        &.round {
            border-radius: 50px;

            &:before {
                border-radius: 50%;
            } 
        }
    }
}
// Dark Style CSS
.theme-dark {
    body {
        background-color: #000000;
        color: #f1f1f1;
    }
    p {
        color: #f1f1f1 !important;
        
        a {
            color: #f1f1f1 !important;

            &:hover {
                color: $primary-color !important;
            }
        }
    }
    a {
        color: $body-color;
    }
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        color: $heading-color !important;
        a {
            color: $heading-color !important;

            &:hover {
                color: $primary-color !important;
            }
        }
    }
    .box-btn {
        background: $primary-color;
        color: $body-color ;
        &:hover {
            background: #6346f0;
            color: $body-color;
        }
    }

    //Navbar
    .navbar-area {
        .navbar-brand {
            .white-logo {
                display: block;
            }
            .main-logo {
                display: none;
            }
        }
        &.is-sticky {
            background-color: #000000;
        }
    }
    .main-nav {
        background-color: #000000;
        .navbar {
            .navbar-nav {
                .nav-item {
                    a {
                        color: $body-color;
                    }
                    .dropdown-menu {
                        background: #0e0e0e;
                        
                        li {
                            a {
                                color: $body-color;
                                border-bottom: 1px dashed #000000;
                            }
                            .dropdown-menu {
                                li {
                                    a {
                                        color: $body-color;
                                        border-bottom: 1px dashed #000000;
                                    }
                                    .dropdown-menu {
                                        li {
                                            a {
                                                color: $body-color;
                                            }
                                            .dropdown-menu {
                                                li {
                                                    a {
                                                        color: $body-color;
                                                    }
                                                    .dropdown-menu {
                                                        li {
                                                            a {
                                                                color: $body-color;
                                                            }
                                                            .dropdown-menu {
                                                                li {
                                                                    a {
                                                                        color: $body-color;
                                                                    }
                                                                    .dropdown-menu {
                                                                        li {
                                                                            a {
                                                                                color: $body-color;

                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                           
                        }
                    }
                }
            }
            .others-options {
                ul {
                    li {
                        .sign-in {
                            color: $body-color;
                        }
                        .cart-icon {
                            i {
                                color: $body-color;
                                &:hover {
                                    color: $primary-color;
                                }
                            }
                        }
                        .search-btn {
                            color: $body-color;
                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
    }
    //Banner
    .slider-area {
        .home-slider {
            .single-slider {
                &::before {
                    background:unset;
                    background-color: #000000;
                    opacity: .7;
                }
            }
            .box-btn {
                background-color: $primary-color;
            }
        }
    }

    //Page Banner
    .banner-area {
        &::before {
            background: unset;
            background-color: #000000;
            opacity: .8;
        }
    }
    .page-title-content {
        ul {
            li {
                a {
                    &::before {
                        background: $body-color;
                    }
                }
                p {
                    &::before {
                        background: $body-color;
                    }
                }
            }
        }
    }

    //Services
    .service-area {
        .single-service {
            background: unset;
            background-color: #0e0e0e;
            .service-content {
                p {
                    color: $body-color;
                }
            }
        }
    }

    //Courses
    .home-ragular-course {
        .single-ragular-course {
            background-color: #0e0e0e;
            .course-img {
                &::before {
                    background: #000000;
                }
            }
            .course-content {
                a {
                    border: 1px solid $body-color;
                    color: $body-color;
                }
            }
        }
    }
    .home-special-course {
        background: #0e0e0e;
    }
    .course-slider-area {
        background-color: #0e0e0e;
        .course-slider {
            background: unset;
            background-color: #000000;
        }
    }
    .class-area {
        .single-ragular-course {
            background: #0e0e0e;
            .course-img {
                &::before {
                    background: #000000;
                }
            }
        }
    }
    .pagination {
        .page-item {
            .page-link {
                background: #0e0e0e;
                color: #fff;
                &:hover {
                    background-color: $primary-color;
                    color: $body-color;
                }
            }
        }
    }

    .single-class-area {
        .class-content-right {
            .class-list {
                li {
                    a {
                        color: $body-color;
                    }
                }
            }
        }
    }


    //About
    .about-area {
        .about-content-right {
            .about-search {
                background-color: #0e0e0e;
                color: $body-color;
                &::placeholder {
                    color: $body-color;
                }
            }
            button {
                color: $body-color;
            }
            .about-list {
                li {
                    a {
                        color: $body-color;
                    }
                }
            }
        }
    }
    .consultation-area {
        background-color: #0e0e0e;
        .form-group {
            .form-control {
                background-color: #000000;
                color: $body-color;
                &::placeholder {
                    color: $body-color;
                }
            }
        }
    }
    .about-search {
        background-color: #0e0e0e;
        color: $body-color;
        &::placeholder {
            color: $body-color;
        }
    }

    //Events
    .events {
        .single-events {
            .content {
                background-color: #0e0e0e;
            }
        }
    }

    //Why Choose
    .choose-area {
        .home-choose-content {
            ul {
                li {
                    color: $body-color;
                }
            }
        }
    }

    //Admission
    .home-admission {
        .single-addmission {
            background-image: unset;
            .admission-circle {
                background: #0e0e0e;
                border-color: #292727;
            }
        }
    }
    .admission-area {
        .admission-form {
            background-color: #0e0e0e;
            .form-group {
                label {
                    color: $body-color;
                }
                .form-control {
                    background-color: #000000;
                    color: $body-color;
                    &::placeholder {
                        color: $body-color;
                    }
                }
            }
        }
    }
    //Price
    .price-area {
        .single-price {
            .price-head {
                background-color: #2a2929;
            }
           background-color: #0e0e0e;
        }
    }

    //Gallery
    .gallery-area {
        .gall-list {
            .single-gall {
                .gall-content {
                    background-color: #0e0e0e;
                }
            }
        }
    }

    //Testimonial
    .testimonials-area {
        .single-testimonials {
            background-color: #0e0e0e;
        }
    }

    //Sign Up
    .signup-area {
        .signup-form {
            background-color: #0e0e0e;
            .form-group {
                .form-control {
                    background-color: #000000;
                    color: $body-color;
                    &::placeholder {
                        color: $body-color;
                    }
                }
            }
        }
    }

    //Faq
    .faq-area {
        .faq-content {
            .faq-accordion {
                .accordion {
                    .accordion-item {
                        .accordion-title {
                            background-color: #0e0e0e;
                            color: $body-color;
                            i {
                                color: $body-color;
                            }
                        }
                        .accordion-content {
                            background-color: #0e0e0e;
                        }
                    }
                }
            }
        }
    }

    //News
    .home-news {
        .single-home-news {
            .single-home-content {
                background-color: #0e0e0e;
            }
        }
    }
    .news {
        .single-news {
            .content {
                background-color: #0e0e0e;
                a {
                    color: $body-color;
                }
            }
        }
    }
    .single-news-area {
        .news-content-right {
            .class-list {
                li {
                    a {
                        color: $body-color;
                    }
                }
            }
        }
    }

    //Teacher
    .home-teachers-area {
        .single-home-teacher {
            .teachers-content {
                background: #0e0e0e;
            }
            .teacher-social {
                &::before {
                   display: none;
                }
            }
        }
    }
    .teachers-area {
        .single-teacher {
            .teachers-content {
                background: #0e0e0e;
            }
            .teacher-social {
                &::before {
                   display: none;
                }
            }
        }
    }
    

    //contact
    .home-contact-area {
        .home-contact-content {
            background: unset;
            background-color: #0e0e0e;
            .form-group {
                .form-control {
                    background-color: #000000;
                    color: $body-color;
                    &::placeholder {
                        color: $body-color;
                    }
                }
            }
        }
    }
    .contact-service {
        .single-service {
            background: unset;
            background-color: #0e0e0e;
        }
    }

    //Fotter
    .footer-area {
        background: unset;
        background-color: #222222;
    }

    @media only screen and (max-width: 991px) {
        .mean-container {
            .mean-bar {
                background-color: #000000;
                padding: 0;
            }
            a {
                &.meanmenu-reveal {
                    span {
                        background-color: #ffffff;
                    }
                }
            }
            .mean-nav {
                ul {
                    li {
                        background: #000000;
                        a {
                            color: $body-color;
                        }
                    }
                }
            }
        }
        .mobile-nav {
            .logo {
                .main-logo {
                    display: none;
                }
                .white-logo {
                    display: block;
                }
            }
        }
    }
}